.cards {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
}

.card {
    padding: 1rem 1rem 1rem 0;
    border: 1px solid $lavender;
    margin-bottom: 1rem;
    background: $light-gray;

    .card-button {
        text-align: right;
        padding: 2rem 0;
    }

    h2, h3 {
        margin-top: 0 !important;
        border-top: none !important;
        padding-top: 0 !important;
       
        
        a{
            color: #347e84;
            text-decoration: none;
        }
    }
}