.toc {
    border: 1px solid $lavender;
    background: $light-gray;
    padding: .5rem;
    position: relative;

    button {
        position: absolute;
        top: 5px;
        right: 5px;
    }
}