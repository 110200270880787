#course {

    line-height: 1.6rem;

    h1 span {
        font-size: .8rem;
        color: $blue;
        width: 100%;
        display: block;
        clear: both;
        margin-bottom: 10px;
    }

    h2, h3 {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px dotted $dark-gray;
    }
    #courseContent {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px dotted $dark-gray; 

    @media (max-width: 800px) {
        flex-direction: column; 
    }

        aside {
            min-width: 300px;
            ul {
                list-style-type: none;
                padding: 0;
                
                li {
                    width: 100%;
                    clear: both;
                    background-color: $light-gray;
                    margin-bottom: 1px;
                    display:block;
                    overflow: hidden;

                    ul {
                        display: none;
                    }

                    a {
                        padding: 5px;
                        text-decoration: none;
                        line-height: 2rem;
                        width: 100%;
                        display: block;
                        color: $black;

                        &:visited {
                            color: $lavender;
                        }
                    }

                    &.activeLesson {
                        background-color: $blue;

                        ul {
                            display: block;

                            a {
                                padding-left: 15px;
                                box-sizing: border-box;

                                &:hover {
                                    background-color: darken($blue, 20%);
                                }

                                i.right {
                                    font-size: .7rem;
                                    margin-top: 10px;
                                }
                            }
                        }
                        a { background-color: $blue; color: white; }
                    }
                    
                }
                a {
                    
                    a {
                        padding-left: 15px;
                        
                    }
                }
                a.activeLesson, a.activeLesson a {
                    
                }
            }

            flex-basis: 25%;
        }

        

        article {
           max-width: 60rem;
            padding-left: 20px;
        }
    }
}

i.right {
    float: right;
}

.lessonContent{
    width: 100%;
    img {
        max-width: 100%;
    }
} 

#quizOverview {
    background-color: #f3f3f3;
    width: 100%;
    padding: .5rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;

    h2 {
        border-top: none;
        width: 100%;
    }

    #quizCalculation {
        width: 100%;
        border-top: 1px dotted #ccc;
        padding: .5rem;
    }

    fieldset {
        border: 1px solid $green;
        background: #fff;
        text-align: center;
        width: 21%;
        box-sizing: border-box;

        legend {
            background-color: $green;
            color: $black;
            padding: .2rem .5rem;
        }
        .currentScore {
            font-size: 4rem;
            text-align: center;
            font-weight: bold;
            margin: 1.2rem;
        }
    }

}