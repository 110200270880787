//Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');

//Variables (HIOF colors)
$white: #fff;
$black: #23201F;
$light-gray: #ECEAE5;
$dark-gray: #C8C2BE;
$blue: #347E84;
$green: #6FC2B4;
$coral: #D77869;
$lavender: #A396A3;

//Global values
html, body {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    color: $black;
}

h1, h2, h3 {
    font-weight: 600;
}

div, aside, header, footer, main, span, article, section {
    box-sizing: border-box;
}

main {
    width: 100%;
    margin: 0 auto;
    max-width: 1320px;
    padding: 20px;
}

.contentTitle {
    width: 100%;
    display: block;
    clear: both;
    margin-bottom: 20px;
    font-size: 2rem;
}

//Add icons - Fontisto.com
@import "fontisto/sass/fontisto/fontisto.scss";
//Layouts
@import "layouts/text";
@import "layouts/header";
@import "layouts/buttons";

//Components
@import "components/cards";
@import "components/signin";
@import "components/courses";
@import "components/course";
@import "components/quiz";
@import "components/toc";