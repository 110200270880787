#courses {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: space-between;
    clear: both;

    article {
        padding: 20px;
        max-width: 400px;
        border: 1px solid $dark-gray;
        background: $light-gray;
    }
}

#courseContent {
    fieldset {
        border: 1px solid $coral;
        padding: 1rem;
        background: adjust($coral, -0.4);
        color: $black;

        legend {
            background: $coral;
            color: $white;
            padding: 1rem;
            font-size: 1.2rem;
            font-weight: 600;
        }
    }
}