.btn {
    padding: 10px;
    cursor: pointer;
    background-color: $black;
    color: $white;
    text-decoration: none;
    border: none;
}

.btn-big {
    width: 100%;
    padding: 20px;
    font-size: 2rem;
}

.btn-blue, .btn-default {
    background-color: $blue !important;
    color: $white;
    
    &:hover {
        background-color: darken($blue, 20%);
    }
}

.btn-right {
    float: right;
}