// Variables
$fi-font-name:              "fontisto";
$fi-css-prefix:             fi !default;
$fi-font-path:              "./sass/fontisto/fonts/" !default;
$fi-font-size:              14px !default; 
$fi-font-size-int:          14;
$fi-font-line-height:       1 !default;
$fi-font-version:           "304" !default;
$fi-font-border-color:      #dedede !default; 
$fi-font-color-inverse:     #fff !default;
$fi-font-li-width:          (30em / 14) !default;


@function unicode($str){
    @return unquote("\"") + $str + unquote("\"");
}

// icon maps (array list)

// Brand
$brands:(
    acrobat-reader: (\eabf),
    applemusic: (\eac0),
    atlassian: (\eac1),
    aws: (\eac2),
    baidu: (\eac3),
    bing: (\eac4),
    bower: (\eac5),
    dailymotion: (\eac6),
    delicious: (\eac7),
    deviantart: (\eac8),
    disqus: (\eac9),
    flipboard: (\eaca),
    graphql: (\eacb),
    hexo: (\eacc),
    hipchat: (\eacd),
    icq: (\eace),
    invision: (\eacf),
    jekyll: (\ead0),
    jira: (\ead1),
    json: (\ead2),
    livestream: (\ead3),
    messenger: (\ead4),
    meteor: (\ead5),
    onenote: (\ead6),
    mongodb: (\ead7),
    netflix: (\ead8),
    nginx: (\ead9),
    odnoklassniki: (\eada),
    onedrive: (\eadb),
    origin: (\eadc),
    pingdom: (\eadd),
    rails: (\eade),
    raspberry-pi: (\eadf),
    redis: (\eae0),
    redux: (\eae1),
    saucelabs: (\eae2),
    scorp: (\eae3),
    sentry: (\eae4),
    shazam: (\eae5),
    shopify: (\eae6),
    sinaweibo: (\eae7),
    slides: (\eae8),
    sublimetext: (\eae9),
    swift: (\eaea),
    ted: (\eaeb),
    telegram: (\eaec),
    tesla: (\eaed),
    tinder: (\eaee),
    treehouse: (\eaef),
    twoo: (\eaf0),
    udacity: (\eaf1),
    webstorm: (\eaf2),
    wix: (\eaf3),
    yandex-international: (\eaf4),
    yandex: (\eaf5),
    ember: (\eab7),
    cpanel: (\eab8),
    viber: (\eab9),
    deskpro: (\eaba),
    discord: (\eabb),
    discourse: (\eabc),
    adobe: (\ea9b),
    algolia: (\ea9c),
    atom: (\ea9d),
    babel: (\ea9e),
    coffeescript: (\ea9f),
    electronjs: (\eaa0),
    mysql: (\eaa1),
    oracle: (\eaa2),
    php: (\eaa3),
    sourcetree: (\eaa4),
    ubuntu: (\eaa5),
    unity: (\eaa6),
    unreal-engine: (\eaa7),
    webpack: (\eaa8),
    angelist: (\ea7a),
    app-store: (\ea7b),
    digg: (\ea7e),
    dockers: (\ea7f),
    envato: (\ea80),
    gitlab: (\ea81),
    google-drive: (\ea82),
    google-play: (\ea83),
    grunt: (\ea84),
    gulp: (\ea85),
    hacker-news: (\ea86),
    imdb: (\ea87),
    jenkins: (\ea88),
    joomla: (\ea89),
    kickstarter: (\ea8a),
    laravel: (\ea8b),
    less: (\ea8c),
    line: (\ea8d),
    npm: (\ea8e),
    periscope: (\ea8f),
    product-hunt: (\ea90),
    quora: (\ea91),
    skyatlas: (\ea92),
    stylus: (\ea93),
    travis: (\ea94),
    trello: (\ea95),
    uber: (\ea96),
    vine: (\ea97),
    visual-studio: (\ea98),
    vk: (\ea99),
    vuejs: (\ea9a),
    microsoft: (\ea79),
    blogger: (\ea7c),
    500px: (\e90a),
    amazon: (\e90b),
    ampproject: (\e90c),
    android: (\e90d),
    angularjs: (\e90e),
    apple: (\e90f),
    behance: (\e910),
    bitbucket: (\e911),
    bluetooth-b: (\e912),
    cloudflare: (\e913),
    codepen: (\e914),
    css3: (\e915),
    dribbble: (\e916),
    dropbox: (\e917),
    facebook: (\e918),
    flickr: (\e919),
    foursquare: (\e91a),
    git: (\e91b),
    github: (\e91c),
    google-plus: (\e91d),
    google: (\e91e),
    hangout: (\e91f),
    houzz: (\e920),
    html5: (\e921),
    instagram: (\e922),
    java: (\e923),
    jquery: (\e924),
    jsfiddle: (\e925),
    linkedin: (\e926),
    linux: (\e927),
    magento: (\e928),
    maxcdn: (\e929),
    medium: (\e92a),
    meetup: (\e92b),
    nodejs: (\e92c),
    opencart: (\e92d),
    pinterest: (\e92e),
    playstation: (\e92f),
    python: (\e930),
    react: (\e931),
    reddit: (\e932),
    ruby: (\e933),
    sass: (\e934),
    skype: (\e935),
    slack: (\e936),
    snapchat: (\e937),
    soundcloud: (\e938),
    spotify: (\e939),
    stack-overflow: (\e93a),
    steam: (\e93b),
    stumbleupon: (\e93c),
    svn: (\e93d),
    swarm: (\e93e),
    tripadvisor: (\e93f),
    tumblr: (\e940),
    twitch: (\e941),
    twitter: (\e942),
    vimeo: (\e943),
    wetransfer: (\e944),
    whatsapp: (\e945),
    wifi-logo: (\e946),
    wikipedia: (\e947),
    windows: (\e948),
    wordpress: (\e949),
    xbox: (\e94a),
    yahoo: (\e94b),
    yelp: (\e94c),
    youtube-play: (\e94d),
    cocoapods: (\eb28),
    composer: (\eb29),
    yarn: (\eb2a)
);

// Interfaces
$interfaces:(
    language: (\ea27),
    toggle-off: (\ea2c),
    toggle-on: (\ea7d),
    anchor: (\eabe),
    archive: (\eaf6),
    at: (\eaf7),
    ban: (\eaf8),
    battery-half: (\eaf9),
    battery-full: (\eafa),
    battery-empty: (\eafb),
    battery-quarter: (\eafc),
    battery-three-quarters: (\eafd),
    bell-alt: (\eafe),
    bell: (\eaff),
    bookmark-alt: (\eb00),
    bookmark: (\eb01),
    bug: (\eb02),
    calculator: (\eb03),
    calendar: (\eb04),
    crosshairs: (\eb05),
    desktop: (\eb06),
    download: (\eb07),
    film: (\eb08),
    history: (\eb09),
    hourglass-end: (\eb0a),
    hourglass-half: (\eb0b),
    hourglass-start: (\eb0c),
    hourglass: (\eb0d),
    info: (\eb0e),
    key: (\eb0f),
    keyboard: (\eb10),
    laptop: (\eb11),
    lightbulb: (\eb12),
    magnet: (\eb13),
    map-marker-alt: (\eb14),
    map-marker: (\eb15),
    map: (\eb16),
    mobile-alt: (\eb17),
    mobile: (\eb18),
    paw: (\eb19),
    phone: (\eb1a),
    power: (\eb1b),
    qrcode: (\eb1c),
    question: (\eb1d),
    search: (\eb1e),
    sitemap: (\eb1f),
    star-half: (\eb20),
    stopwatch: (\eb21),
    tablet-alt: (\eb22),
    tablet: (\eb23),
    ticket: (\eb24),
    tv: (\eb25),
    upload: (\eb26),
    user-secret: (\eb27),
    camera: (\e996),
    clock: (\e997),
    close-a: (\e998),
    code: (\e999),
    comment: (\e99a),
    commenting: (\e99b),
    comments: (\e99c),
    crop: (\e99d),
    cursor: (\e99e),
    database: (\e99f),
    date: (\e9a0),
    earth: (\e9a1),
    email: (\e9a2),
    eye: (\e9a3),
    female: (\e9a4),
    favorite: (\e9a5),
    filter: (\e9a6),
    fire: (\e9a7),
    flag: (\e9a8),
    flash: (\e9a9),
    home: (\e9aa),
    link: (\e9ab),
    locked: (\e9ac),
    male: (\e9ad),
    minus-a: (\e9ae),
    more-v-a: (\e9af),
    more-v: (\e9b0),
    move-h-a: (\e9b1),
    move-h: (\e9b2),
    nav-icon-a: (\e9b3),
    nav-icon-grid-a: (\e9b4),
    nav-icon-grid: (\e9b5),
    nav-icon-list-a: (\e9b6),
    nav-icon-list: (\e9b7),
    nav-icon: (\e9b8),
    navigate: (\e9b9),
    paper-plane: (\e9ba),
    person: (\e9bb),
    persons: (\e9bc),
    picture: (\e9bd),
    plus-a: (\e9be),
    print: (\e9bf),
    quote-a-left: (\e9c0),
    quote-a-right: (\e9c1),
    quote-left: (\e9c2),
    quote-right: (\e9c3),
    reply: (\e9c4),
    rss: (\e9c5),
    scissors: (\e9c6),
    share-a: (\e9c7),
    share: (\e9c8),
    trash: (\e9c9),
    unlocked: (\e9ca),
    usb: (\e9cb),
    wifi: (\e9cc),
    world-o: (\e9cd),
    world: (\e9ce),
    zoom: (\e9cf),
    adjust: (\ea6f),
    recycle: (\ea70),
    pinboard: (\eabd),
    zoom-minus: (\eab2),
    zoom-plus: (\eab3),
    check: (\eab4),
    asterisk: (\eab5),
    hashtag: (\eab6),
    checkbox-active: (\e982),
    checkbox-passive: (\e983),
    radio-btn-active: (\e984),
    radio-btn-passive: (\e985)
);

// Shopping
$shopping:(
    shopping-bag-1: (\ea6b),
    shopping-bag: (\ea6c),
    shopping-barcode: (\ea71),
    shopping-basket-add: (\ea72),
    shopping-basket-remove: (\ea73),
    shopping-basket: (\ea74),
    shopping-package: (\ea75),
    shopping-pos-machine: (\ea76),
    shopping-sale: (\ea77),
    shopping-store: (\ea78)
);

// Arrows
$arrows:(
    angle-dobule-down: (\e964),
    angle-dobule-left: (\e965),
    angle-dobule-right: (\e966),
    angle-dobule-up: (\e967),
    angle-down: (\e968),
    angle-left: (\e969),
    angle-right: (\e96a),
    angle-up: (\e96b),
    arrow-down-l: (\e96c),
    arrow-down: (\e96d),
    arrow-expand: (\e96e),
    arrow-h: (\e96f),
    arrow-left-l: (\e970),
    arrow-left: (\e971),
    arrow-move: (\e972),
    arrow-resize: (\e973),
    arrow-return-left: (\e974),
    arrow-return-right: (\e975),
    arrow-right-l: (\e976),
    arrow-right: (\e977),
    arrow-swap: (\e978),
    arrow-up-l: (\e979),
    arrow-up: (\e97a),
    arrow-v: (\e97b),
    caret-down: (\e97c),
    caret-left: (\e97d),
    caret-right: (\e97e),
    caret-up: (\e97f),
    fi: (\e980),
    fontisto: (\e981)
);

// Player
$player: (
    backward: (\e9da),
    eject: (\e9db),
    equalizer: (\e9dc),
    forward: (\e9dd),
    headphone: (\e9de),
    heart: (\e9df),
    mic: (\e9e0),
    music-note: (\e9e1),
    pause: (\e9e2),
    play-list: (\e9e3),
    play: (\e9e4),
    player-settings: (\e9e5),
    podcast: (\e9e6),
    random: (\e9e7),
    record: (\e9e8),
    star: (\e9e9),
    step-backwrad: (\e9ea),
    step-forward: (\e9eb),
    stop: (\e9ec),
    volume-down: (\e9ed),
    volume-mute: (\e9ee),
    volume-off: (\e9ef),
    volume-up: (\e9f0),
    airplay: (\ea68)
);


// Editors
$editors:(
    bold: (\ea0e),
    broken-link: (\ea0f),
    center-align: (\ea10),
    close: (\ea11),
    columns: (\ea12),
    copy: (\ea13),
    eraser: (\ea14),
    export: (\ea15),
    file-1: (\ea16),
    file-2: (\ea17),
    folder: (\ea18), 
    font: (\ea19),
    import: (\ea1a),
    indent: (\ea1b),
    italic: (\ea1c),
    justify: (\ea1d),
    left-align: (\ea1e),
    link2: (\ea1f),
    list-1: (\ea20),
    list-2: (\ea21),
    outdent: (\ea22),
    paperclip: (\ea23),
    paragraph: (\ea24),
    paste: (\ea25),
    preview: (\ea26),
    print2: (\ea27),
    redo: (\ea28),
    right-align: (\ea29),
    save-1: (\ea2a),
    save: (\ea2b),
    scissors2: (\ea2c),
    strikethrough: (\ea2d),
    subscript: (\ea2e),
    superscript: (\ea2f),
    table-1: (\ea30),
    table-2: (\ea31),
    text-height: (\ea32),
    text-width: (\ea33),
    underline: (\ea34),
    undo: (\ea35)
);

// Weather
$weather:(
    cloud-down: (\ea36),
    cloud-refresh: (\ea37),
    cloud-up: (\ea38),
    cloudy-gusts: (\ea39),
    cloudy: (\ea3a),
    compass: (\ea3b),
    day-cloudy: (\ea3c),
    day-haze: (\ea3d),
    day-lightning: (\ea3e),
    day-rain: (\ea3f),
    day-snow: (\ea40),
    day-sunny: (\ea41),
    fog: (\ea42),
    horizon-alt: (\ea43),
    horizon: (\ea44),
    lightning: (\ea45),
    lightnings: (\ea46),
    night-alt-cloudy: (\ea47),
    night-alt-lightning: (\ea48),
    night-alt-rain: (\ea49),
    night-alt-snow: (\ea4a),
    night-clear: (\ea4b),
    rain: (\ea4c),
    rainbow: (\ea4d),
    rains: (\ea4e),
    snow: (\ea4f),
    snows: (\ea50),
    thermometer: (\ea51),
    umbrella: (\ea52),
    wind: (\ea53)
);

// Emoji
$emoji:(
    confused: (\ea54),
    dizzy: (\ea55),
    expressionless: (\ea56),
    frowning: (\ea57),
    heart-eyes: (\ea58),
    laughing: (\ea59),
    mad: (\ea5a),
    nervous: (\ea5b),
    neutral: (\ea5c),
    open-mouth: (\ea5d),
    rage: (\ea5e),
    slightly-smile: (\ea5f),
    smiley: (\ea60),
    smiling: (\ea61),
    stuck-out-tongue: (\ea62),
    sunglasses: (\ea63),
    surprised: (\ea64),
    tongue: (\ea65),
    wink: (\ea66),
    zipper-mouth: (\ea67)   
);

// Medical
$medical:(
    aids: (\eb49),
    ambulance: (\eb4a),
    bandage: (\eb4b),
    bed-patient: (\eb4c),
    blood-drop: (\eb4d),
    blood-test: (\eb4e),
    blood: (\eb4f),
    dna: (\eb50),
    doctor: (\eb51),
    drug-pack: (\eb52),
    first-aid-alt: (\eb53),
    heart-alt: (\eb54),
    heartbeat-alt: (\eb55),
    heartbeat: (\eb56),
    helicopter-ambulance: (\eb57),
    hospital: (\eb58),
    injection-syringe: (\eb59),
    laboratory: (\eb5a),
    nurse: (\eb5b),
    nursing-home: (\eb5c),
    paralysis-disability: (\eb5d),
    pills: (\eb5e),
    prescription: (\eb5f),
    pulse: (\eb60),
    stethoscope: (\eb61),
    surgical-knife: (\eb62),
    tablets: (\eb63),
    test-bottle: (\eb64),
    test-tube-alt: (\eb65),
    test-tube: (\eb66),
    thermometer-alt: (\eb67)
);

// Payment
$payment:(
    american-express: (\e9d0),
    credit-card: (\e9d1),
    google-wallet: (\e9d2),
    iyzigo: (\e9d3),
    mastercard: (\e9d4),
    paypal-p: (\e9d5),
    paypal: (\e9d6),
    payu: (\e9d7),
    troy: (\e9d8),
    visa: (\e9d9),
    dinners-club: (\ea6a),
    apple-pay: (\ea69),
    discover: (\ea6d),
    jcb: (\ea6e)    
);

// Hand
$hand:(
    dislike: (\e994),
    like: (\e995)
);

// Accessibility
$accessibility:(
    audio-description: (\e900),
    blind: (\e901),
    braille: (\e902),
    deaf: (\e903),
    fa-american-sign-language-interpreting: (\e904),
    low-vision: (\e906),
    tty: (\e907),
    universal-acces: (\e908),
    wheelchair: (\e909)
);

// Chart
$chart:(
    area-chart: (\e954),
    bar-chart: (\e955),
    line-chart: (\e956),
    pie-chart-1: (\e957),
    pie-chart-2: (\e958)
);

// Browser
$browser:(
    chrome: (\e94e),
    edge: (\e94f),
    firefox: (\e950),
    internet-explorer: (\e951),
    opera: (\e952),
    safari: (\e953)
);

// Currency
$currency:(
    bitcoin: (\e959),
    dollar: (\e95a),
    euro: (\e95b),
    gbp: (\e95c),
    gg: (\e95d),
    ils: (\e95e),
    inr: (\e95f),
    krw: (\e960),
    rouble: (\e961),
    tl: (\e962),
    yen: (\e963)
);

// Gender
$gender:(
    genderless: (\e986),
    intersex: (\e987),
    mars-double: (\e988),
    mars-stroke-h: (\e989),
    mars-stroke-v: (\e98a),
    mars-stroke: (\e98b),
    mars: (\e98c),
    mercury: (\e98d),
    neuter: (\e98e),
    transgender-alt: (\e98f),
    transgender: (\e990),
    venus-double: (\e991),
    venus-mars: (\e992),
    venus: (\e993)
);

// Transportation
$transportation:(
    automobile: (\e9fb),
    bicycle: (\e9fc),
    bus: (\e9fd),
    car: (\e9fe),
    helicopter: (\e9ff),
    metro: (\ea00),
    motorcycle: (\ea01),
    plane: (\ea02),
    rocket: (\ea03),
    ship: (\ea04),
    subway: (\ea05),
    taxi: (\ea06),
    train: (\ea07),
    truck: (\ea08),
    yacht: (\ea09)
);

// Travel
$travel:(
    beach-slipper: (\eb2b),
    bus-ticket: (\eb2c),
    cocktail: (\eb2d),
    compass-alt: (\eb2e),
    direction-sign: (\eb2f),
    do-not-disturb: (\eb30),
    flotation-ring: (\eb31),
    holiday-village: (\eb32),
    hot-air-balloon: (\eb33),
    hotel-alt: (\eb34),
    hotel: (\eb35),
    island: (\eb36),
    money-symbol: (\eb37),
    parasol: (\eb38),
    passport-alt: (\eb39),
    passport: (\eb3a),
    photograph: (\eb3b),
    plane-ticket: (\eb3c),
    room: (\eb3d),
    sait-boat: (\eb3e),
    snorkel: (\eb3f),
    suitcase-alt: (\eb40),
    suitcase: (\eb41),
    sun: (\eb42),
    sunglasses-alt: (\eb43),
    swimsuit: (\eb44),
    tent: (\eb45),
    ticket-alt: (\eb46),
    train-ticket: (\eb47),
    wallet: (\eb48)
);

// Spinner
$spinner:(
    circle-o-notch: (\e9f1),
    propeller-1: (\e9f2),
    propeller-2: (\e9f3),
    propeller-3: (\e9f4),
    propeller-4: (\e9f5),
    spinner-cog: (\e9f6),
    spinner-fidget: (\e9f7),
    spinner-refresh: (\e9f8),
    spinner-rotate-forward: (\e9f9),
    spinner: (\e9fa)
);

// Snowflake
$snowflake:(
    snowflake: (\eab1),
    snowflake-1: (\eaa9),
    snowflake-2: (\eaaa),
    snowflake-3: (\eaab),
    snowflake-4: (\eaac),
    snowflake-5: (\eaad),
    snowflake-6: (\eaae),
    snowflake-7: (\eaaf),
    snowflake-8: (\eab0)
);

// Shapes
$shapes:(
    curve: (\ea0b), 
    ellipse: (\ea0c),
    rectangle: (\ea0d),
    shield: (\ea0a)
);

$iconList:(
    $brands,
    $interfaces,
    $shopping,
    $arrows,
    $player,
    $editors,
    $weather,
    $emoji,
    $medical,
    $payment,
    $hand,
    $accessibility,
    $chart,
    $browser,
    $currency,
    $gender,
    $transportation,
    $travel,
    $spinner,
    $snowflake,
    $shapes
);