header {
    width: 100%;
    background: #f3f3f3;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    #web-logo{
        a{
            text-decoration: none;
            font-weight: 900;
            color: #347e84;

            &:hover {
                color: darken(#347e84, 20%);
            }
        }
    }

    nav {
        display:flex;
        align-items: center;
        justify-content: flex-end;
        ul {
            list-style-type: none;
            padding: 10px;
            display:flex;

            .profile {
                
            }
        }
    }
}