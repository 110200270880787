.group {
    display: flex;
    margin-bottom: 5px;
    align-items: center;

    label {
        margin-left: 5px;
        margin-right: 5px;
        padding-top: 10px;
    }

    p {
        padding-top: 5px;
        color: $coral;
        margin: 0;
    }
}

.quizProgress {
    width: 100%;
    display: block;
    border-radius: 5px;
    overflow: hidden;
    background: rgb(168,16,16);
    background: linear-gradient(90deg, rgba(168,16,16,1) 0%, rgba(214,200,31,1) 42%, rgba(4,79,25,1) 100%);
    border: 2px solid $black;
    box-sizing: border-box;

    div {
        background: $black;
        float: right;
        padding: 5px;
        display: block;
        overflow: hidden;
        box-sizing: border-box;
        color: white;
        height: 30px;
    }
}

fieldset {
    border: 1px solid $coral;
    margin-bottom: 10px;
}